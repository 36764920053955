import React, { useState } from 'react';
import classNames from 'classnames';
import { FaSun, FaMoon, FaChevronRight } from 'react-icons/fa';
import { BsGear, BsFileCode, BsDownload } from 'react-icons/bs';
import { AiOutlineApi } from 'react-icons/ai';
import styles from './App.module.scss';
import StatementEditor from './StatementEditor';
import ProjectSettings from './ProjectSettings';
import FileDownloads from './FileDownloads';
import Toggle from '../components/Toggle';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { ReactComponent as LDLogo } from '../assets/ld.svg';

const defaultTab = 0;

const buildInfo = {
  version: process.env.REACT_APP_VERSION,
};

if (process.env.REACT_APP_BUILD_TIME) {
  buildInfo.updated = process.env.REACT_APP_BUILD_TIME;
}

export default function App() {
  const buildCheck = React.createRef();
  const [darkMode, setDarkMode] = useLocalStorage('ld-darkMode', false);
  const [projectSettings, updateProjectSettings] = useLocalStorage(
    'ld-projectSettings',
    {}
  );
  const [tab, setTab] = useLocalStorage('ld-tab-data', { index: defaultTab });
  const [appInfo, setAppInfo] = React.useState(null);
  const toggleBuildInfo = React.useCallback(() => {
    return appInfo ? setAppInfo(null) : setAppInfo(buildInfo);
  }, [appInfo, setAppInfo]);
  const handleTripleClick = React.useCallback(
    (evt) => evt.detail === 3 && toggleBuildInfo(),
    [toggleBuildInfo]
  );

  React.useEffect(() => {
    buildCheck.current?.addEventListener('click', handleTripleClick);
    const node = buildCheck.current;
    return () => {
      node?.removeEventListener('click', handleTripleClick);
    };
  }, [buildCheck, handleTripleClick]);

  const formatDate = (seconds) => {
    if (!seconds) {
      return 'Build Date Unknown';
    }
    const date = new Date(0);
    date.setUTCSeconds(parseInt(seconds, 10));
    return date.toUTCString();
  };

  const tabs = [
    {
      id: 'project-settings',
      title: (
        <>
          <BsGear className="mr-2" />
          <span className="sm:hidden inline-block">Settings</span>
          <span className="sm:inline-block hidden">Project Settings</span>
        </>
      ),
      content: (
        <ProjectSettings
          projectSettings={projectSettings}
          updateProjectSettings={updateProjectSettings}
        />
      ),
    },
    {
      id: 'statement-generator',
      title: (
        <>
          <BsFileCode className="mr-2" />{' '}
          <span className="sm:hidden inline-block">Statements</span>
          <span className="sm:inline-block hidden">Statement Generator</span>
        </>
      ),
      content: <StatementEditor projectSettings={projectSettings} />,
    },
    {
      id: 'file-downloads',
      title: (
        <>
          <BsDownload className="mr-2" /> Downloads
        </>
      ),
      content: <FileDownloads projectSettings={projectSettings} />,
    },
  ];

  const mailLink = `mailto:admin@learningdojo.net?subject=Beta Feedback: Learning Dojo | xAPI Builder&body=Feedback for Learning Dojo xAPI Builder ${new Date()}`;

  return (
    <div
      className={classNames(
        'overflow-auto absolute top-0 top-32 pt-4 bottom-12 w-full',
        {
          dark: darkMode,
          'bg-gray-900 text-white': darkMode,
          'bg-gray-100 text-gray-900': !darkMode,
        },
        styles.App
      )}
    >
      <header className="fixed top-0 left-0 w-full shadow bg-transparent text-white h-32 block flex flex-col items-center justify-between whitespace-nowrap">
        <div className="shadow bg-blue-500 w-full flex items-center justify-between border border-transparent dark:border-white dark:border-opacity-10 border-opacity-10 dark:bg-gray-800 mx-auto z-2 relative flex-1">
          <div className="w-11/12 max-w-4xl flex items-center justify-between px-2 py-4 mx-auto ">
            <h1 className="font-light sm:text-2xl flex items-center">
              <LDLogo className="w-12 h-12 mr-2" /> xAPI Builder{' '}
              <a
                href={mailLink}
                className="text-xs md:mt-1 px-2 block rounded-md bg-blue-500 border border-white bg-opacity-10 ml-2"
              >
                BETA
              </a>
            </h1>
            <div className="flex items-center justify-center">
              <a
                className="flex items-center text-xs md:text-sm py-1 px-3 mx-3 md:mx-10 rounded bg-white bg-opacity-10 hover:bg-opacity-20"
                href="https://www.udemy.com/course/xapi-fundamentals-getting-started-with-xapi-aka-tincan/?couponCode=XAPI-BUILDER"
                target="_blank"
                rel="noreferrer"
              >
                Learn More About xAPI
              </a>
              <Toggle
                active={darkMode}
                setActive={setDarkMode}
                offLabel={<FaSun className="text-sm" />}
                onLabel={<FaMoon className="text-sm" />}
              />
            </div>
          </div>
        </div>
        <div className="bg-white dark:bg-gray-900 w-full relative z-0 border-b border-transparent dark:border-white dark:border-opacity-10">
          <div className="w-11/12 max-w-4xl mx-auto px-2 flex items-center justify-start">
            {tabs.map(({ id, title }, i) => (
              <button
                type="button"
                style={{ marginBottom: '-1px' }}
                className={classNames(
                  'py-2 text-gray-500 mr-4 mt-1 hover:text-blue-400 outline-none focus:outline-none focus:text-blue-600 border-b-4 border-transparent flex items-center justify-center',
                  {
                    'border-blue-500 text-blue-500': i === tab.index,
                  }
                )}
                key={id}
                onClick={() => setTab({ index: i })}
              >
                {title}
              </button>
            ))}
          </div>
        </div>
      </header>
      <div className="w-11/12 max-w-4xl mx-auto pt-2">
        {tabs[tab.index || 0].content}
      </div>
      <footer
        className="fixed bottom-0 left-0 w-full shadow-lg bg-white dark:bg-gray-800 border-t border-gray-500 border-opacity-25"
        ref={buildCheck}
      >
        <div className="flex w-11/12 max-w-4xl mx-auto py-4 px-2 justify-center items-center h-12">
          <small className="opacity-75 select-none text-center">
            <span className="whitespace-nowrap">
              &copy; {new Date().getFullYear()} Learning Dojo LLC
            </span>
            {appInfo && (
              <>
                <span className="whitespace-nowrap text-blue-600 dark:text-blue-400 border border-blue-600 text-xs bg-blue-400 bg-opacity-25 px-2 rounded-full ml-2">
                  {appInfo.version}
                </span>
                <span className="whitespace-nowrap text-green-600 dark:text-green-400 border border-green-600 text-xs bg-green-400 bg-opacity-25 px-2 rounded-full ml-2">
                  {formatDate(appInfo.updated)}
                </span>
              </>
            )}
          </small>
        </div>
      </footer>
    </div>
  );
}
