import React, { useState } from 'react';
import classNames from 'classnames';
import {
  FaCopy,
  FaTrashAlt,
  FaList,
  FaMagic,
  FaInfoCircle,
} from 'react-icons/fa';
import TagList from '../components/TagList';
import Statement from '../components/Statement';
import tags from '../data/tags.json';
import { inputClass } from '../utils/styleUtils';
import { useLocalStorage } from '../hooks/useLocalStorage';

const preferredUrlPart = 'w3id';
const uniqueTags = Array.from(new Set(tags.map((tag) => tag.label))).map(
  (label) => {
    let url;
    url = tags.find((t) => t.label === label && t.url.includes('w3id'))?.url;
    if (!url) {
      url = tags.find((t) => t.label === label).url;
    }
    return {
      label,
      url,
    };
  }
);

function copyClipboard(id) {
  const item = document.getElementById(id);
  const elm = item.querySelector('pre');
  // for Internet Explorer

  if (document.body.createTextRange) {
    const range = document.body.createTextRange();
    range.moveToElementText(elm);
    range.select();
    document.execCommand('Copy');
  } else if (window.getSelection) {
    // other browsers

    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(elm);
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand('Copy');
  }
}

const defaultTitle = 'xAPI Statement Title (replace)';

export default function StatementEditor(props) {
  const [tag, setTag] = useLocalStorage('ld-tag', '');
  const [title, setTitle] = useLocalStorage('ld-title', defaultTitle);
  const [description, setDescription] = useLocalStorage('ld-description', '');
  const [statements, setStatements] = useLocalStorage('ld-statements', []);
  const [copiedItem, setCopiedItem] = React.useState({});
  const [saving, setSaving] = React.useState(0);
  const { projectSettings } = props;
  const { activityId } = projectSettings || {};

  const setCopied = (val, id) => {
    setCopiedItem({
      ...copiedItem,
      [id]: val,
    });
  };

  const { url = '' } = uniqueTags.find((item) => tag === item.label) || {};
  const removeItem = (id) => {
    setStatements([...statements].filter((item) => item.id !== id));
  };

  const discardItem = () => {
    setTag('');
    setTitle(defaultTitle);
    setDescription('');
  };

  const newStatementRef = React.useRef(null);

  const addItem = () => {
    const id = Math.random() * 230230123;
    setSaving(newStatementRef.current.scrollHeight);
    setStatements([
      {
        tag,
        title,
        description,
        url,
        id,
        activityId,
      },
      ...statements,
    ]);
    discardItem();
    setTimeout(() => {
      setSaving(0);
    }, 0);
  };

  const discardStatements = () => {
    setStatements([]);
  };

  return (
    <>
      <section className="px-2 pt-4 flex flex-col md:flex-row">
        <div className="flex-1 pb-2 md:mr-2">
          {' '}
          <h2 className="mb-2 inline-flex items-center justify-start">
            Verb*{' '}
            <a
              href="http://xapi.vocab.pub/verbs/index.html"
              target="_blank"
              rel="noreferrer"
              className="ml-2 hover:text-blue-500 focus:text-blue-500"
            >
              <FaInfoCircle className="opacity-90" />
            </a>
          </h2>
          <TagList items={uniqueTags} onSelect={setTag} />
        </div>
        <div className="flex-1 md:ml-2 pb-2 flex flex-col">
          <label>
            Title*
            <input
              placeholder="Title for xAPI statement"
              className={inputClass}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </label>
          <label className="flex flex-col flex-1 pt-4">
            Description
            <textarea
              placeholder="Optional description for xAPI statement"
              className="mt-2 outline:none focus:outline-none focus:border-blue-500 dark:bg-black dark:bg-opacity-10 border-2 py-1 px-2 border-gray-300 w-full rounded-md flex-1"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </label>
        </div>
      </section>
      <small className="px-2 w-full text-right block opacity-50">
        * Required fields
      </small>
      <section className="px-2 pb-4">
        <h2 className="mb-2">Preview</h2>
        <div className="transform ">
          <Statement
            tag={tag}
            title={title}
            description={description}
            url={url}
            ref={newStatementRef}
            activityId={activityId}
            className={classNames(
              'transition-transform transform duration-500 shadow-lg',
              {
                'scale-105': tag,
                'scale-100': !tag,
              }
            )}
            emptyContent={
              !tag && (
                <div className="mx-auto md:w-1/2 p-6 md:px-0 text-left flex items-center justify-center opacity-75">
                  <FaMagic className="text-3xl mr-4 flex-none" />
                  <span className="block text-left">
                    To create a new statement, select a <strong>verb</strong>{' '}
                    and update the <strong>title</strong> (and optionally add{' '}
                    <strong>description</strong>)
                  </span>
                </div>
              )
            }
            actions={() => (
              <div className="text-right flex items-center justify-end">
                <button
                  type="button"
                  onClick={discardItem}
                  className={classNames(
                    'rounded-md h-8 text-white px-3 py-1 ml-2 border-2 border border-blue-500 text-sm text-blue-500'
                  )}
                  title="Discard current statement"
                >
                  Discard
                </button>
                <button
                  type="button"
                  onClick={addItem}
                  className={classNames(
                    'rounded-md h-8 text-white px-3 py-1 ml-2 border border-gray-300 text-sm',
                    {
                      'bg-gray-300 cursor-default dark:bg-opacity-10 dark:border-opacity-0':
                        !tag || !title,
                      'bg-blue-500 border-blue-500 dark:border-blue-500':
                        tag && title,
                    }
                  )}
                  title={
                    !tag || !title
                      ? 'Please add tag and title'
                      : 'Save statement'
                  }
                  disabled={!tag || !title}
                >
                  Save
                </button>
              </div>
            )}
          />
        </div>
      </section>
      <section className="px-2 py-4">
        <h2 className="flex justify-between items-center">
          <span className="block py-1">
            Statements {statements.length > 0 && `(${statements.length})`}
          </span>
          {statements.length > 1 && (
            <button
              className="flex items-center bg-white dark:bg-red-500 dark:bg-opacity-5 bg-opacity-5 border-red-500 justify-center border rounded-md h-8 px-2 text-sm text-red-500"
              type="button"
              onClick={discardStatements}
            >
              <FaTrashAlt className="mr-1" /> <span>Delete all</span>
            </button>
          )}
        </h2>
        {statements.length > 0 ? (
          <ul className="py-2 overflow-hidden">
            {statements.map((item, i) => (
              <li key={item.id}>
                <Statement
                  {...item}
                  style={
                    i === 0
                      ? {
                          marginTop: saving !== 0 ? `-${saving + 22}px` : 0,
                          transition: 'margin 500ms ease-in-out',
                        }
                      : {}
                  }
                  actions={() => (
                    <div className="flex items-center justify-end">
                      <button
                        className="flex items-center bg-white dark:bg-red-500 dark:bg-opacity-5 bg-opacity-5 border-red-500 justify-center border rounded-md h-8 w-8 text-sm text-red-500"
                        type="button"
                        onClick={() => removeItem(item.id)}
                      >
                        <FaTrashAlt />
                      </button>
                      <button
                        className="relative overflow-visible bg-blue-500 ml-2 text-white rounded-md w-8 h-8 inline-flex text-md items-center justify-center"
                        type="button"
                        onClick={() => {
                          setCopied(true, item.id);
                          copyClipboard(item.id);
                          setTimeout(() => {
                            setCopied(false, item.id);
                          }, 3000);
                        }}
                      >
                        <FaCopy />
                        <div
                          className={classNames(
                            'absolute bottom-full text-xs transform transition-all duration-500 w-64 text-blue-500 pointer-events-none',
                            {
                              'opacity-100 translate-y-0 scale-100':
                                copiedItem[item.id],
                              'opacity-0 translate-y-full scale-0':
                                !copiedItem[item.id],
                            }
                          )}
                        >
                          Copied!
                        </div>
                      </button>
                    </div>
                  )}
                />
              </li>
            ))}
          </ul>
        ) : (
          <div
            title="Statements will appear here once generated"
            className="flex mt-2 items-center justify-center flex-col p-10 opacity-50 bg-gray-500 bg-opacity-10 rounded-md"
          >
            <FaList className="text-4xl mb-2 opacity-50" />
          </div>
        )}
      </section>
    </>
  );
}
