import React, { useState } from 'react';
import classNames from 'classnames';

export default function Toggle({ setActive, offLabel, onLabel, active }) {
  return (
    <div className="flex rounded-full border-2 border-white overflow-hidden transform scale-75 md:scale-100">
      <button
        className={classNames(
          'transition-all duration-200 flex items-center justify-center rounded-full w-6 h-6 overflow-hidden outline-none focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-300',
          {
            'bg-white text-gray-900': !active,
            'hover:bg-white hover:bg-opacity-25': active,
          }
        )}
        type="button"
        onClick={setActive.bind(this, false)}
      >
        {offLabel}
      </button>
      <button
        className={classNames(
          'transition-all duration-200 flex items-center justify-center rounded-full w-6 h-6 overflow-hidden outline-none focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-300',
          {
            'bg-white text-gray-900': active,
            'hover:bg-white hover:bg-opacity-25': !active,
          }
        )}
        type="button"
        onClick={setActive.bind(this, true)}
      >
        {onLabel}
      </button>
    </div>
  );
}
