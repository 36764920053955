/* eslint-disable import/prefer-default-export */
export const inputClass = `
  outline:none focus:outline-none focus:border-blue-500
  dark:bg-gray-900 border-2 py-1 px-2
  border-gray-300 w-full rounded-md mt-2
`;

export const inputClassError = `
  outline:none focus:outline-none focus:border-red-500
  dark:bg-gray-900 border-2 py-1 px-2
  border-red-300 w-full rounded-md mt-2
`;
