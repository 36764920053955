import React, { useState } from 'react';
import { FaDownload, FaRegFileCode, FaPaperclip } from 'react-icons/fa';
import { BiErrorAlt } from 'react-icons/bi';
import classNames from 'classnames';
import * as clipboard from 'clipboard-polyfill/text';
import ldxapiTemplate from '../templates/ldxapi.js.ld';
import xapiwrapperTemplate from '../templates/xapiwrapper.js.ld';
import cryptojsTemplate from '../templates/cryptojs_v3.1.2.js.ld';
import projectConfig from '../data/projectConfig.json';

const errorClass = `
  bg-red-100 bg-opacity-50 text-red-500 px-3 dark:bg-opacity-100 dark:bg-gray-900 py-2 rounded-md block mt-1 flex-none w-auto inline-flex items-center justify-between border border-red-500 text-red-500
`;

function copyClipboard(elm) {
  if (document.body.createTextRange) {
    const range = document.body.createTextRange();
    range.moveToElementText(elm);
    range.select();
    document.execCommand('Copy');
  } else if (window.getSelection) {
    // other browsers

    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(elm);
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand('Copy');
  }
}

function download(filename, text) {
  const element = document.createElement('a');
  element.setAttribute(
    'href',
    `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`
  );
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

const getProjectVariable = (variable, projectSettings) => {
  const { projectType = 'storyline' } = projectSettings;

  return projectConfig[projectType][variable];
};

export default function FileDownloads(props) {
  const { projectSettings = {} } = props;
  const [ldxapiText, setLdxapiText] = useState('ERROR_SETTING_VARIABLE');
  const [xapiwrapperText, setXapiwrapperText] = useState(
    'ERROR_SETTING_VARIABLE'
  );
  const [copied, setCopied] = useState(false);
  const [cryptojsText, setCryptojsText] = useState('ERROR_SETTING_VARIABLE');
  const [error, setError] = useState('');
  const copyTextareaContent = React.useCallback(
    (e) => {
      copyClipboard(e.target);
      setCopied(true);
      setTimeout(() => {
        e.target.select();
      }, 0);
      setTimeout(() => {
        setCopied(false);
      }, 5000);
    },
    [setCopied]
  );
  React.useEffect(() => {
    const { projectType, key, secret, lrsEndpoint } = projectSettings;
    if (!projectType) {
      setError(
        'Please select an Authoring Tool on the Project Settings tab before proceeding'
      );
      return false;
    }
    fetch(ldxapiTemplate)
      .then((r) => r.text())
      .then((text) => {
        const parsedTemplate = text
          .replace(
            /%emailAddress%/g,
            getProjectVariable('emailAddress', projectSettings)
          )
          .replace(
            /%userName%/g,
            getProjectVariable('userName', projectSettings)
          )
          .replace(
            /%objectID%/g,
            getProjectVariable('objectID', projectSettings)
          );
        setLdxapiText(parsedTemplate);
      });
    if (!key || !secret || !lrsEndpoint) {
      const missingItems = [];
      if (!lrsEndpoint) missingItems.push('Endpoint');
      if (!key) missingItems.push('key');
      if (!secret) missingItems.push('secret');
      setError(
        `Missing LRS info: ${missingItems
          .join(', ')
          .toString()}. Please check Project Settings tab and adjust before proceeding.`
      );
      return false;
    }
    if (!secret) {
      setError(
        'Missing LRS secret, please check Project Settings tab and adjust before proceeding.'
      );
      return false;
    }
    if (!lrsEndpoint) {
      setError(
        'Missing LRS Endpoint, please check Project Settings tab and adjust before proceeding.'
      );
      return false;
    }
    fetch(xapiwrapperTemplate)
      .then((r) => r.text())
      .then((text) => {
        const parsedTemplate = text
          .replace(/%key%/g, key)
          .replace(/%secret%/g, secret)
          .replace(/%lrsEndpoint%/g, lrsEndpoint);
        setXapiwrapperText(parsedTemplate);
      });
    fetch(cryptojsTemplate)
      .then((r) => r.text())
      .then((text) => {
        setCryptojsText(text);
      });
    return true;
  }, [projectSettings]);

  const getDownloadLinks = React.useCallback(
    () => [
      {
        fileName: 'cryptojs_v3.1.2.js',
        content: cryptojsText,
        showErrors: false,
      },
      {
        fileName: 'ldxapi.js',
        content: ldxapiText,
        showErrors: true,
      },
      {
        fileName: 'xapiwrapper.js',
        content: xapiwrapperText,
        showErrors: true,
      },
    ],
    [cryptojsText, ldxapiText, xapiwrapperText]
  );

  return (
    <div className="px-1 -mt-6">
      {error && (
        <div className="mt-8 text-center">
          <div className={errorClass}>
            <BiErrorAlt className="w-12 mr-2 text-2xl flex-0 md:w-auto" />{' '}
            {error}
          </div>
        </div>
      )}
      <div className={classNames('relative')}>
        <div className="absolute top-0 z-0 block w-0 h-full transform -translate-x-1/2 border-l-2 border-blue-500 border-dashed left-4 dark:border-blue-500" />
        <h2 className="relative flex items-center mt-10 mb-2 text-2xl z-1">
          <span className="flex items-center justify-center w-8 h-8 mr-2 text-sm font-bold bg-white border-2 border-blue-500 rounded-full dark:border-blue-500 dark:bg-gray-900">
            1
          </span>
          Download the files
          <FaDownload className="pl-2" />
        </h2>
        <p className="mb-4 ml-10 opacity-95">
          The files contain specific configurations that can be managed in the
          Project Settings tab.
        </p>
        <div
          className={classNames('flex flex-col md:flex-row ml-10')}
          title={error}
        >
          {getDownloadLinks().map(
            ({ content, fileName, showErrors }, index) => {
              return (
                <button
                  type="button"
                  className={classNames(
                    'flex-1 shadow-md hover:shadow-lg mb-2 bg-white dark:bg-gray-800 dark:bg-opacity-100 rounded-md items-center px-4 py-4 flex border-4 border-transparent',
                    {
                      'text-red-500': error && showErrors,
                      'md:mr-4': index < getDownloadLinks().length - 1,
                    }
                  )}
                  onClick={() => download(fileName, content)}
                >
                  {error && showErrors && (
                    <BiErrorAlt className="text-2xl text-red-500" />
                  )}
                  {(!error || !showErrors) && (
                    <>
                      <FaRegFileCode className="pl-2 text-2xl" />{' '}
                    </>
                  )}
                  <span className="ml-2 text-lg text-left">{fileName}</span>
                </button>
              );
            }
          )}
        </div>
        <h2 className="relative flex items-center mt-10 mb-2 text-2xl z-1">
          <span className="flex items-center justify-center w-8 h-8 mr-2 text-sm font-bold bg-white border-2 border-blue-500 rounded-full dark:border-blue-500 dark:bg-gray-900">
            2
          </span>
          Attach the files
          <FaPaperclip className="pl-2" />
        </h2>
        <div className="relative pt-2 pl-10 -mt-2 bg-gray-100 z-2 dark:bg-gray-900">
          <p className="mb-4 opacity-95">
            Based on your selected authoring tool{' '}
            <strong className="text-blue-500">
              ({getProjectVariable('displayName', projectSettings)})
            </strong>
            , here is an instructional video with the steps necessary to attach
            the files:
          </p>
          <p className="relative overflow-hidden">
            <span className="opacity-95">
              Place the downloaded files inside your project directory in the{' '}
              <strong className="text-blue-500">
                {getProjectVariable('outputFolderName', projectSettings)}
              </strong>{' '}
              sub-folder and add the following tags tags to the{' '}
              <strong className="text-blue-500">
                {getProjectVariable('outputFileName', projectSettings)}
              </strong>{' '}
              file
            </span>
            <textarea
              style={{ resize: 'none' }}
              className="outline-none focus:outline-none focus:border-opacity-100 block w-full h-24 px-4 pt-3 pb-0 my-2 font-mono whitespace-pre-line bg-blue-500 border-2 border-blue-500 border-opacity-25 rounded bg-opacity-10 dark:bg-black dark:bg-opacity-25"
              onFocus={copyTextareaContent}
              onClick={copyTextareaContent}
              readOnly
              tabIndex={0}
              value={getProjectVariable('tagInstructions', projectSettings)}
            />
            <span
              className={classNames(
                'z-2 px-2 py-1 rounded absolute bg-blue-500 text-white absolute top-full -mt-4 right-2 transition-all duration-300 transform',
                {
                  'opacity-100 -translate-y-full': copied,
                  'opacity-0 translay-y-0': !copied,
                }
              )}
            >
              Copied to clipboard!
            </span>
          </p>
          <div className="flex flex-col md:flex-row">
            <div
              className={classNames(
                'flex-1 mb-2 bg-white dark:bg-gray-800 dark:bg-opacity-100 shadow-lg rounded-lg items-center p-0 flex border-4 border-transparent'
              )}
            >
              <div className="relative w-full overflow-hidden pb-9/16">
                <iframe
                  src="https://player.vimeo.com/video/630486460?h=5927bbae38&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                  className="absolute object-cover w-full h-full rounded-md"
                  title="xAPI Builder"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
