import classNames from 'classnames';
import React, { forwardRef } from 'react';
import tags from '../data/tags.json';

function cleanString(string) {
  return string.replace(/"/g, '\\"');
}

const sendFunctionName = 'sendBasicStatement';

const checkVerb = (verb) => {
  return true;
  // return tags.filter((tag) => tag.label === verb).length > 0;
};

const Statement = (
  {
    actions,
    tag,
    title,
    description,
    url,
    id,
    className: statementClassName,
    emptyContent,
    style,
    activityId = '',
  },
  ref
) => {
  const [verbId, setVerbId] = React.useState(url);
  const [verb, setVerb] = React.useState(tag);

  React.useEffect(() => {
    setVerbId(url);
  }, [url]);

  React.useEffect(() => {
    setVerb(tag);
  }, [tag]);

  const statementData = React.useCallback(() => {
    const verbValid = checkVerb(verb);
    return {
      verbId: {
        val: verbValid
          ? cleanString(verbId)
          : 'Error: please select a valid verb from the list above', // verb id uri
        editable: false,
        className: classNames({
          'bg-red-500 bg-opacity-25 text-red-500': !verbValid,
        }),
      },
      verb: {
        val: cleanString(verb), // verb
        className: classNames('bg-opacity-25 rounded-sm', {
          'bg-blue-500 text-blue-500': verbValid,
          'bg-red-500 text-red-500': !verbValid,
        }),
        editable: false /* todo - inline editing would be valuable to enable in future */,
        onChange: (val) => {
          setVerb(val);
        },
      },
      objectName: {
        val: cleanString(title),
        editable: false /* todo - inline editing would be valuable to enable in future */,
      },
      objectDesc: {
        val: cleanString(description),
        editable: false /* todo - inline editing would be valuable to enable in future */,
      },
    };
  }, [verb, verbId, description, title]);

  const statementEntries = Object.entries(statementData());

  return (
    <div
      className={classNames(
        'rounded-md bg-white dark:bg-black dark:bg-opacity-10 p-4 mb-4 relative overflow-hidden',
        statementClassName
      )}
      id={id}
      style={style}
      ref={ref}
    >
      {emptyContent ? (
        <>{emptyContent}</>
      ) : (
        <>
          <pre
            className={classNames(
              'whitespace-pre-wrap break-all text-sm border-l-2 border-blue-500 bg-gray-500 bg-opacity-10 mb-4 p-2'
            )}
          >
            {sendFunctionName}(
            {statementEntries.map(([key, value], i) => {
              const { editable, className, onChange, val } = value;
              const opts = {};
              if (editable) {
                opts.contentEditable = true;
                opts.suppressContentEditableWarning = true;
                if (onChange) {
                  opts.onChange = onchange;
                }
              }
              const joiner = i < statementEntries.length - 1 ? <>, </> : '';
              return (
                <React.Fragment key={key}>
                  &quot;
                  <span className={className} {...opts}>
                    {val}
                  </span>
                  &quot;{joiner}
                </React.Fragment>
              );
            })}
            )
          </pre>
          {actions && actions()}
        </>
      )}
    </div>
  );
};

export default forwardRef(Statement);
