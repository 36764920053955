import React, { useState } from 'react';
import classNames from 'classnames';

export default function Tag({
  label,
  hoverText,
  onSelect,
  interactive = true,
  tabIndex,
}) {
  return label ? (
    <button
      title={hoverText}
      className={classNames(
        'px-3 py-1 text-sm text-blue-500 rounded border-blue-500 border-2 outline-none mr-2 mb-2 transition-all duration-200 bg-white dark:bg-gray-900',
        {
          'hover:bg-blue-500 dark:hover:bg-blue-500 active:bg-blue-500 dark:active:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:bold hover:text-white active:text-white':
            interactive,
          'cursor-default': !interactive,
        }
      )}
      disabled={!interactive}
      type="button"
      onClick={onSelect.bind(this, label)}
      tabIndex={tabIndex}
    >
      {label}
    </button>
  ) : (
    <></>
  );
}
