import classNames from 'classnames';
import React, { useState } from 'react';
import { FaSearch, FaSortAlphaDown, FaRandom } from 'react-icons/fa';
import Tag from './Tag';
import styles from './TagList.module.scss';

export default function TagList({ items, onSelect }) {
  const [orderedItems, setOrderedItems] = React.useState(() => [...items]);
  const [random, setRandom] = React.useState(0);
  const [maxItems, setMaxItems] = React.useState(25);
  const [searchTerm, updateSearchTerm] = React.useState('');

  React.useEffect(() => {
    if (random) {
      setOrderedItems([...items].sort((a, b) => 0.5 - Math.random()));
    }
  }, [random, items]);

  const resetItemOrder = () => {
    setOrderedItems([...items]);
    setRandom(0);
  };

  // eslint-disable-next-line prettier/prettier
  const itemsFiltered = orderedItems.filter((item) => {
    return item.label.includes(searchTerm.toLowerCase());
  });
  const atMax = maxItems === items.length;
  const toggleMax = () => {
    resetItemOrder();
    setMaxItems(atMax ? 25 : items.length);
  };

  return (
    <div className="bg-blue-200 dark:bg-blue-600 dark:bg-opacity-10 bg-opacity-50 rounded-md p-2 border-2 border-blue-200 border-opacity-10 dark:border-gray-800 dark:border-opacity-50 ">
      <div className="p-1 pb-3">
        <input
          value={searchTerm}
          onChange={(e) => updateSearchTerm(e.target.value)}
          placeholder="Start typing to filter available verbs"
          className="outline:none focus:outline-none focus:border-blue-500 dark:bg-black  dark:bg-opacity-10 bg-white border-2 py-1 px-2 border-blue-300 w-full rounded-md"
        />
      </div>
      {itemsFiltered.length > 0 && (
        <div
          className={classNames(
            'select-none r-1 mb-1 overflow-auto',
            styles.Scroller,
            {
              'h-64': searchTerm && itemsFiltered.length < maxItems,
            }
          )}
        >
          <ul
            className={classNames(
              'flex flex-wrap p-1 justify-start items-start',
              {
                'max-h-80': atMax,
              }
            )}
          >
            {itemsFiltered.slice(0, maxItems).map(({ label, url }) => (
              <li key={url}>
                <Tag
                  label={label}
                  hoverText={url}
                  onSelect={onSelect}
                  tabIndex={searchTerm ? 0 : -1}
                />
              </li>
            ))}
            {!atMax && !(itemsFiltered.length < maxItems) && (
              <Tag
                key="toggler"
                hoverText="showMore"
                label="..."
                onSelect={toggleMax}
                tabIndex={0}
              />
            )}
          </ul>
        </div>
      )}
      {itemsFiltered.length === 0 && (
        <div className="flex h-64 w-full justify-center items-center flex-col opacity-50 text-blue-900 dark:text-blue-200 dark:opacity-25">
          <FaSearch className="text-6xl mb-2" />
          <h3>No matches found</h3>
        </div>
      )}
      <div className="flex justify-between items-center p-1 select-none">
        <small>
          {`Showing ${Math.min(maxItems, itemsFiltered.length)} of ${
            items.length
          }`}
        </small>
        <div className={classNames('select-none')}>
          {!searchTerm && !atMax && random !== 0 && (
            <button
              className="text-sm rounded bg-white text-blue-500 dark:text-white bg-opacity-75 dark:bg-blue-500 dark:bg-opacity-25 py-2 px-2 mr-2"
              onClick={resetItemOrder}
              type="button"
            >
              <FaSortAlphaDown />
            </button>
          )}
          {!searchTerm && !atMax && (
            <button
              className="text-sm rounded bg-white text-blue-500 dark:text-white bg-opacity-75 dark:bg-blue-500 dark:bg-opacity-25 py-2 px-2"
              onClick={() => {
                setRandom(Math.random());
              }}
              type="button"
            >
              <FaRandom />
            </button>
          )}
          {searchTerm && (
            <button
              className={classNames(
                {
                  'mr-2': atMax,
                },
                'text-sm rounded bg-white text-blue-500 dark:bg-blue-500 dark:bg-opacity-25 py-1 px-2'
              )}
              onClick={() => updateSearchTerm('')}
              type="button"
            >
              Clear search
            </button>
          )}
          {atMax && (
            <button
              className="text-sm rounded bg-blue-500 text-white dark:bg-blue-500 dark:bg-opacity-25 py-1 px-2"
              onClick={toggleMax}
              type="button"
            >
              Show less
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
