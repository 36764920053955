import React, { useState } from 'react';
import { FaEye, FaEyeSlash, FaExclamationTriangle } from 'react-icons/fa';
import classNames from 'classnames';
import { inputClass, inputClassError } from '../utils/styleUtils';
import { ReactComponent as CaptivateLogo } from '../assets/captivate.svg';
import { ReactComponent as HTML5Logo } from '../assets/html5.svg';
import { ReactComponent as StorylineLogo } from '../assets/storyline.svg';

const errorClass = `
  bg-red-100 bg-opacity-50 text-red-500 px-2 py-1 rounded-md block mt-1 flex-none w-auto text-sm inline-flex items-center justify-between
`;

export default function ProjectSettings({
  projectSettings,
  updateProjectSettings,
}) {
  const formRef = React.createRef();
  const [errors, setErrors] = React.useState({});
  const [showSecret, setShowSecret] = React.useState(false);
  const { lrsEndpoint, activityId, key, secret, projectType } = projectSettings;
  const updateField = (settingKey, settingVal) => {
    updateProjectSettings({
      ...projectSettings,
      [settingKey]: settingVal,
    });
  };

  const matchSoFar = (str, pattern) => {
    return str === pattern.replace(/\?/g, '').substring(0, str.length);
  };

  const validate = (type, e, errorMessage) => {
    const regMatch = e.target.value.match(e.target.pattern);
    const parMatch = matchSoFar(e.target.value, e.target.pattern);
    if (regMatch || parMatch) {
      setErrors({
        ...errors,
        [type]: false,
      });
    } else {
      setErrors({
        ...errors,
        [type]: errorMessage,
      });
    }
  };

  return (
    <section className="px-2 pt-4">
      <form onSubmit={(e) => e.preventDefault()}>
        <h2 className="mb-2">LRS Configuration</h2>
        <div className="bg-white dark:bg-gray-800 shadow-lg dark:bg-opacity-100 rounded-md p-8">
          <div className="flex flex-col md:flex-row">
            <label className="block flex-1 relative">
              LRS EndPoint
              <input
                placeholder="LRS EndPoint (https://...)"
                className={errors.endpoint ? inputClassError : inputClass}
                value={lrsEndpoint}
                onChange={(e) => {
                  updateField('lrsEndpoint', e.target.value);
                  validate('endpoint', e, 'LRS EndPoint must be a valid URL');
                }}
                pattern="https?://.*"
                type="url"
                onBlur={(e) => {
                  validate('endpoint', e, 'LRS EndPoint must be a valid URL');
                }}
              />
              {errors.endpoint && (
                <div className={errorClass}>
                  {errors.endpoint} <FaExclamationTriangle className="ml-2" />
                </div>
              )}
            </label>
          </div>
          <div className="flex flex-col md:flex-row">
            <label className="mt-2 block flex-1 relative md:mr-2">
              Key
              <input
                placeholder="Key"
                className={inputClass}
                value={key}
                onChange={(e) => updateField('key', e.target.value)}
              />
            </label>
            <label className="mt-2 block flex-1 relative md:ml-2">
              Secret
              <input
                placeholder="Secret"
                className={inputClass}
                value={secret}
                onChange={(e) => updateField('secret', e.target.value)}
                type={showSecret ? 'text' : 'password'}
              />
              <button
                type="button"
                className="absolute right-1 top-1/2 flex items-center justify-center h-8 w-8 rounded-md bg-white dark:bg-gray-900 dark:text-white"
                onClick={(e) => setShowSecret(!showSecret)}
              >
                {showSecret ? (
                  <FaEye className="w-6" />
                ) : (
                  <FaEyeSlash className="w-8" />
                )}
              </button>
            </label>
          </div>
        </div>
        {/*
        <h2 className="mb-2 mt-10">Project-level Activity Configuration</h2>
        <div className="bg-white dark:bg-gray-800 shadow-lg dark:bg-opacity-100 rounded-md p-8">
          <div className="flex flex-col md:flex-row">
            <label className="block flex-1 relative md:ml-2">
              Activity ID
              <input
                placeholder="Activity ID (https://...)"
                className={errors.activity ? inputClassError : inputClass}
                value={activityId}
                onChange={(e) => {
                  updateField('activityId', e.target.value);
                  validate('activity', e, 'Activity ID must be a URL');
                }}
                pattern="https?://.*"
                type="url"
                onBlur={(e) => {
                  validate('activity', e, 'Activity ID must be a URL');
                }}
              />
              {errors.activity && (
                <div className={errorClass}>
                  {errors.activity} <FaExclamationTriangle className="ml-2" />
                </div>
              )}
            </label>
          </div>
        </div>
        */}
        <h2 className="mb-2 mt-10">Choose Authoring Tool</h2>
        <div className="flex flex-col md:flex-row">
          <button
            type="button"
            className={classNames(
              'flex-1 mb-2 bg-white dark:bg-gray-800 dark:bg-opacity-100 rounded-md p-8 flex items-center justify-center border-4 border-transparent',
              {
                'shadow-lg border-blue-500': projectType === 'storyline',
                'shadow-md hover:shadow-lg': projectType !== 'storyline',
              }
            )}
            onClick={() => updateField('projectType', 'storyline')}
          >
            <StorylineLogo className="w-12 h-12" />
            <span className="text-xl ml-2 text-center">Storyline</span>
          </button>
          <button
            type="button"
            className={classNames(
              'flex-1 mb-2 bg-white dark:bg-gray-800 dark:bg-opacity-100 rounded-md p-8 md:mx-4 flex items-center justify-center border-4 border-transparent',
              {
                'shadow-lg border-blue-500': projectType === 'captivate',
                'shadow-md hover:shadow-lg': projectType !== 'captivate',
              }
            )}
            onClick={() => updateField('projectType', 'captivate')}
          >
            <CaptivateLogo className="w-12 h-12" />
            <span className="text-xl ml-2 text-center">Captivate</span>
          </button>
          <button
            type="button"
            className={classNames(
              'flex-1 mb-2 bg-white dark:bg-gray-800 dark:bg-opacity-100 rounded-md p-8 flex items-center justify-center border-4 border-transparent',
              {
                'shadow-lg border-blue-500': projectType === 'html5',
                'shadow-md hover:shadow-lg': projectType !== 'html5',
              }
            )}
            onClick={() => updateField('projectType', 'html5')}
          >
            <HTML5Logo className="w-12 h-12" />
            <span className="text-xl ml-2 text-center">HTML</span>
          </button>
        </div>
      </form>
    </section>
  );
}
